import * as React from "react";

export default function MemoryLongTextModal(props: any) {
  document.body.classList.add('modal-open')
  return (
    <div className="modal fade show">
      <div className="modal-dialog modal-dialog-centered memory-more-popup">
        <div className="modal-content" style={stylesInline.bgColor}>
          <div className="modal-header d-flex flex-column align-items-center pt-4">
            <h3 className="modal-title text-deepblue" id="modalCenterTitle">{props.item.name}</h3>
            <button type="button"
                    className="close"
                    onClick={() => props.onClick()}
            >
              <img src="/images/icons/icon_close-darkblue.svg" alt="close button"/>
            </button>
          </div>
          <div className="modal-body pr-3 pl-3 pt-0">
            <div className="row book-modal__fields no-gutters">
              <div className="col-12 text-center">
                <div
                  className="form-group memory-group w-100 mb-2 mt-3 text-deepblue d-flex flex-column flex-xl-row text-left align-items-center">
                  <p className="pr-3" dangerouslySetInnerHTML={{__html: props.item.text}}></p>
                  {Object.values(props.item.media)[0]?.url ?
                    <img src={Object.values(props.item.media)[0]?.url}/> : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
}

const stylesInline: { [key: string]: React.CSSProperties } = {
  bgColor: {
    backgroundColor: '#fff'
  },
};