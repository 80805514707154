import * as React from "react";
import {useState} from "react";
import {Router} from "@reach/router";
import type {PageProps} from "gatsby"

import MemorialGrid from "../../components/memorial-grid";
import SingleObituary from "../../components/memorial-obituary";
import Registration from "../../components/registration";

import "../../styles/page-home.scss";

export default function Memorials(props: PageProps) {
  const [item, setItem] = useState();

  return (
    <>
      <Router>
        <MemorialGrid path="/memorial/" title="Memorial pages"/>
        <SingleObituary path="memorial/:memoryId/" setmeta={(thisItem) => setItem(thisItem)}/>
        <Registration path="memorial/:memoryId/registration/" setmeta={(thisItem) => setItem(thisItem)}/>
      </Router>
    </>
  );
};