import * as React from "react";
import {useState} from "react";
import {useFenixContext} from "../../helpers/context";

export default function CandleFormModal() {
  const {state, dispatch} = useFenixContext()
  const [name, setName] = useState('');
  const [nameDirty, setNameDirty] = useState(false);
  const [nameError, setNameError] = useState('Letters only, please.');
  const [focusedName, setFocusedName] = useState(false)

  const onFocusName = () => setFocusedName(true)
  const onBlurName = () => setFocusedName(false)

  const nameHandler = (e: any) => {
    setName(e.target.value)
    const letters = /^[A-Za-z]+$/;
    if (e.target.value.length < 2 || !letters.test(String(e.target.value))) {
      setNameError('Letters only, please.')
      setNameDirty(true)
    } else {
      setNameDirty(false)
      setNameError('')
    }
  }

  return (
    <div className="modal fade show">
      <div className="modal-dialog modal-dialog-centered candle-popup">
        <div className="modal-content" style={stylesInline.bgColor}>
          <div className="modal-header d-flex flex-column align-items-center pt-4">
            <h3 className="modal-title text-white" id="modalCenterTitle">Tänd ett ljus</h3>
            <button type="button"
                    className="close"
                    onClick={() => dispatch({type: "hideModal"})}
            >
              <img src="/images/icons/icon_close-white.svg" alt="close button"/>
            </button>
          </div>
          <div className="modal-body">
            <form id="candleForm" className="form-style book-modal">
              <div className="row book-modal__fields no-gutters">
                <div className="col-12 text-center">
                  <img src="/images/icons/icon_candle-big.svg" alt="icon candle" width={120} height={120}
                       className="mt-2 mb-2"/>
                  <div className="form-group name-group w-100 mb-2 mt-3">
                    <input
                      onChange={(e) =>
                        nameHandler(e)
                      }
                      value={name}
                      type="text"
                      className="form-control"
                      id="nameInput"
                      name="name"
                      minLength={2}
                      autoComplete="given-name"
                      placeholder="Från vem?"
                    />
                  </div>
                </div>
              </div>
              <div className="row no-gutters book-modal__submit">
                <div className="col-12 d-flex  justify-content-center">
                  <button className="text-white bg-darkyellow">Send</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
}

const stylesInline: { [key: string]: React.CSSProperties } = {
  bgColor: {
    backgroundColor: '#002733'
  },
};