import * as React from "react";
import {useState} from "react";

export default function ReportFormModal(props: any) {
  const [userReports, setUserReports] = useState();
  const [displayNotice, setDisplayNotice] = useState(false)
  document.body.classList.add('modal-open')
  const clickMemoryReport = (item) => {
    if (item.id) {
      const formData = new FormData();
      formData.append("memory_id", item.id);
      formData.append("memorial_id", item.memorial_id);
      formData.append("text", "Reported");
      const requestOptions = {
        method: 'POST',
        body: formData
      };
      fetch(process.env.FENIX_LOCATION_AJAX_URL + 'api/memorials/report-memory/', requestOptions)
        .then(response => response)
        .then(data => {
          let reportsStored: any = [];
          const reportsExistant = window.localStorage.getItem("fenix_memory_reports");
          reportsExistant && reportsStored.push(reportsExistant);
          reportsStored.push(item.id);
          window.localStorage.setItem("fenix_memory_reports", reportsStored);
          setUserReports(reportsStored);
        });
      setDisplayNotice(true)
    }
  }
  return (
    <div className="modal fade show">
      <div className="modal-dialog modal-dialog-centered memory-report-popup">
        <div className="modal-content" style={stylesInline.bgColor}>
          <div className="modal-header d-flex flex-column align-items-center pt-4">
            <div className="modal-body__icon">
              <img className="modal-body__icon" src="/images/icons/icon_warning-deepblue.svg" width={70} height={70}
                   alt="Warning icon"/>
            </div>
            <button type="button"
                    className="close"
                    onClick={() => props.onClick()}
            >
              <img src="/images/icons/icon_close-darkblue.svg" alt="close button"/>
            </button>
          </div>
          <div className="modal-body text-center">
            {!displayNotice && <div className="memory-report-popup__content">
              <h3 className="modal-title text-deepblue text-center mb-3">Tell us why you want to report<br/> this post.
              </h3>
              <textarea className="modal-body__abuse-textarea w-100" name="abuse-text" id="abuse-text" cols={55}
                        rows={7}
                        placeholder="Your message..."></textarea>
            </div>}
            {displayNotice &&
              <div className="memory-report-popup__success d-flex justify-content-center align-items-center">
                <h3>Thank you for your message</h3>
              </div>}
          </div>
          <div className="modal-footer">
            {!displayNotice && <button type="button" className="btn btn-primary btn-blue" id="submit-abuse-report"
                                       onClick={() => clickMemoryReport(props.item)}>Send
            </button>}
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
}

const stylesInline: { [key: string]: React.CSSProperties } = {
  bgColor: {
    backgroundColor: '#fff'
  },
};