import * as React from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Mousewheel, Navigation} from "swiper";

export default function MemoryImageModal(props: any) {
  document.body.classList.add('modal-open')
  let filteredMemo = props.item?.filter(function (el) {
    return Object.keys(el?.media).length > 0
  });
  console.log(filteredMemo)
  return (
    <div className="modal fade show">
      <div className="modal-dialog modal-dialog-centered memory-more-popup">
        <div className="modal-content" style={stylesInline.bgColor}>
          <div className="modal-header d-flex flex-column align-items-center pt-4">
            <h3 className="modal-title text-deepblue" id="modalCenterTitle">{}</h3>
            <button type="button"
                    className="close"
                    onClick={() => props.onClick()}
            >
              <img src="/images/icons/icon_close-darkblue.svg" alt="close button"/>
            </button>
          </div>
          <div className="modal-body modal-body--image pr-3 pl-3 pt-0">
            <div className="row book-modal__fields no-gutters">
              <div className="col-12 text-center">
                <div className="form-group form-group--image w-100 mb-2 mt-3">
                  {filteredMemo && <Swiper
                    id="modalSwiper"
                    modules={[Mousewheel, Navigation]}
                    navigation={true}
                    centeredSlides={true}
                    grabCursor={true}
                    slidesPerView={1}
                    keyboard={true}
                    allowTouchMove={true}
                    simulateTouch={true}
                  >
                    {filteredMemo.map(function (item, i) {
                      return (
                        <SwiperSlide key={i} className={"d-flex flex-column"}>
                          <div className="modal-body__slide">
                            <h3>{item?.name}</h3>
                            <img src={Object.values(item.media)[0]?.url} alt="Fenix Funeral memo"/>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>}
                  {/*{Object.values(props.item.media)[0]?.url ?*/}
                  {/*  <img src={Object.values(props.item.media)[0]?.url} className="w-100"/> : null}*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
}

const stylesInline: { [key: string]: React.CSSProperties } = {
  bgColor: {
    backgroundColor: '#fff'
  },
};