import * as React from "react"

import {useFenixContext} from '../helpers/context';

export default function DonateCard(props: any) {
  const {state, dispatch} = useFenixContext()
  return (
    <div className="obituary-fund__card text-center d-flex flex-column justify-content-between">
      <div className="obituary-fund__head">
        <a href={props.fundUrl}>
          <img src={props.fundImage} alt="fund logo" className="obituary-fund__logo mb-4" width={180}/>
        </a>
      </div>
      <div className="obituary-fund__body">
        <h3 className="obituary-fund__cta mb-4">Donate in<br/> memory</h3>
        <div className="obituary-fund__title mb-4">{props.fundTitle}</div>
        {props.fundDesc && <div className="obituary-fund__text mb-3">{props.fundDesc}</div>}
      </div>
      <div className="obituary-fund__footer align-self-center">
        {props.fundUrl && <a title={props.fundTitle} href={props.fundUrl} target="_blank" rel="noopener"
                             className="obituary-fund__link text-white d-flex align-items-center justify-content-center">Donate</a>}
      </div>
    </div>
  );
}