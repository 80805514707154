import * as React from "react";
import {useState} from "react";
import {useCopyToClipboard} from 'usehooks-ts'

export default function ShareFormModal(props: any) {
  const [value, copy] = useCopyToClipboard()
  const [noticeVisible, setNoticeVisible] = useState(false);
  document.body.classList.add('modal-open')
  const displayNotice = () => {
    setNoticeVisible(true);

    setTimeout(() => {
      setNoticeVisible(false);
    }, 1000);
  }
  return (
    <div className="modal fade show">
      <div className="modal-dialog modal-dialog-centered memory-report-popup">
        <div className="modal-content" style={stylesInline.bgColor}>
          <div className="modal-header d-flex flex-column align-items-center pt-4">
            <div className="modal-body__icon">
              <img className="modal-body__icon" src="/images/icons/icon_candle-holder.svg" width={70} height={70}
                   alt="Candle holder icon"/>
            </div>
            <button type="button"
                    className="close"
                    onClick={() => props.onClick()}
            >
              <img src="/images/icons/icon_close-darkblue.svg" alt="close button"/>
            </button>
          </div>
          <div className="modal-body text-center">
            <h3 className="modal-title text-deepblue text-center mb-3">Share this page</h3>
            <div className="share-wrapper d-flex justify-content-center">
              <a
                href={"https://www.facebook.com/sharer/sharer.php?u=https://fenixfuneral.co.uk/_seo-scripts/memorial/share.php?u=" + props.item}
                target="_blank" rel="noopener">
                <button type="button" id="shareFacebookBtn" className="share-facebook">
                  <img src="/images/icons/icon_facebook-white.svg" alt="Facebook logo"/>Facebook
                </button>
              </a>
            </div>
            <hr className="mt-4"/>
          </div>
          <div
            className="modal-footer d-flex flex-column flex-xl-row justify-content-center justify-content-xl-between align-items-center pt-0">
            <div id="clipboard-input-wrapper" className="share-input w-100">
              <input type="text" id="clipboardLink" defaultValue={"https://fenixfuneral.co.uk" + props.item}/>
              {noticeVisible && <div className="clipboard-input-success">
                <div>The link has now been copied</div>
              </div>}
            </div>
            <button id="copyButton" type="button" className="btn btn-primary btn-blue mt-3 mt-xl-0"
                    onClick={() => {
                      copy("https://fenixfuneral.co.uk" + props.item);
                      displayNotice()
                    }}>Copy link
            </button>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
}

const stylesInline: { [key: string]: React.CSSProperties } = {
  bgColor: {
    backgroundColor: '#fff'
  },
};