import * as React from "react";
import {useState, useEffect} from "react";
import {Link} from "gatsby";

import {useFenixContext} from "../../helpers/context";
import {Navigation, EffectFade} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import SlideNextButton from "../swiper/next-button";
import "swiper/css/effect-fade";

export default function MemoryFormModal() {
  const {state, dispatch} = useFenixContext()
  const [loading, setloading] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [year, setYear] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [type, setType] = useState("Photo")
  const [textMessage, setTextMessage] = useState(type === "Photo" ? "FENIX_MEMORY_TEXT" : "");
  const [submitDone, setSubmitDone] = useState(false);

  const [emailError, setEmailError] = useState(null);
  const [emailDirty, setEmailDirty] = useState(false);
  const [nameError, setNameError] = useState("Letters only, please.");
  const [nameDirty, setNameDirty] = useState(false);
  const [messageDirty, setMessageDirty] = useState(false);
  const [messageError, setMessageError] = useState(null);
  const [imageError, setImageError] = useState(null);

  const handleChange = (e: any) => {
    setType(e.target.value);
    setTextMessage(e.target.value === "Photo" ? "FENIX_MEMORY_TEXT" : "");
  };

  const nameHandler = (e: any) => {
    // console.log("nameHandler",e.target.value,name);
    setName(e.target.value);
    const letters = /^[a-zA-ZÄÖÅ0-9!;:|?.,_ åáãâäàéêëèíîïìóõôöòøúûüùçñ\-'‘’`"“”&#]+$/;
    if (e.target.value.length < 3 ) {
      setNameError("Please enter your name.");
      setNameDirty(true);
    } else {
      setNameDirty(false);
      setNameError("");
    }
  };

  const messageHandler = (e: any) => {
    const text = /^[a-zA-ZÄÖÅ0-9!;:|?/.,_ åáãâäàéêëèíîïìóõôöòøúûüùçñ\-'‘’`"“”&#\n]+$/;
    setTextMessage(e.target.value);
    if (e.target.value.length < 3 ) {
      setMessageError("Please enter your memory.");
      setMessageDirty(true);
    } else {
      setMessageDirty(false);
      setMessageError("");
    }
  };

  const yearHandler = (e: any) => {
    setYear(e.target.value);
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const emailHandler = (e: any) => {
    // console.log("emailHandler",e.target.value,email);
    setEmail(e.target.value);
    if (!isValidEmail(e.target.value)) {
      setEmailError('Please enter a valid email');
      setEmailDirty(true);
    } else {
      setEmailError(null);
      setEmailDirty(false);
    }
  };

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
      setImageError("");
    } else {
      setImageError("Please attach image");
    }
  }, [selectedImage]);

  const handleSubmit = () => {

    if (state?.data?.id && !submitDone) {
      setSubmitDone(true);
      setloading(true);
      const formData = new FormData();
      formData.append("memorial_id", state?.data.id);
      formData.append("name", name);
      formData.append("email", email);
      formData.append("text", textMessage);
      // console.log('Memory pre-submit0',formData);

      selectedImage && formData.append('files[]', selectedImage);
      // console.log('Memory pre-submit1',formData);
      const requestOptions = {
        method: 'POST',
        body: formData
      };
      // console.log('Memory pre-submit',formData,requestOptions,JSON.stringify(Object.fromEntries(formData)));
      fetch(process.env.FENIX_LOCATION_AJAX_URL + 'api/memorials/save-memory/', requestOptions)
        .then(response => response)
        .then((response) => {
          if (response) {
            return response.json();
          }
          throw new Error('Fetch error');
        })
        .then((memoryData: any) => {
          let memoriesStored: any = [];
          const memoriesExistant = window.localStorage.getItem("fenix_memories");
          memoriesExistant && memoriesStored.push(memoriesExistant);
          memoriesStored.push(memoryData.db_resp);
          window.localStorage.setItem("fenix_memories", memoriesStored);
          fetch(process.env.FENIX_LOCATION_AJAX_URL + "_seo-scripts/memorial/").then(response => response).then((response) => {
            window.location.reload();
          });
        });
    }
  }

  console.log(textMessage)
  console.log(type)

  return (
    <div className="modal fade show">
      <div className="modal-dialog modal-dialog-centered memory-popup">
        <div className="modal-content">
          <form id="memoryForm" className="form-style book-modal">
            <button type="button" className="close" onClick={() => dispatch({type: "hideModal"})}>
              <img src="/images/icons/icon_close.svg" alt="close button"/>
            </button>
            <Swiper
              className={"memory-swiper"}
              modules={[Navigation, EffectFade]}
              updateOnWindowResize={true}
              slidesPerView={1}
              observer={true}
              observeParents={true}
              grabCursor={false}
              keyboard={false}
              allowTouchMove={false}
              mousewheel={false}
              simulateTouch={false}
              passiveListeners={false}
              effect={"fade"}
              centeredSlides={true}
              fadeEffect={{
                crossFade: true,
              }}
              onProgress={(swiper, progress) => {
                // console.log("swiper,progress", swiper.isEnd, progress);
                if (progress == 1 && !submitDone) {
                  // handleSubmit();
                }
              }}
            >
              <SwiperSlide className="swiper-slide swiper-slide--first mx-auto flex-column">
                <div className="row w-100">
                  <div className="col-12">
                    <h3 className="swiper-slide__title text-deepblue text-center">What would you like to share?</h3>
                    <div className="memory-wrapper d-flex flex-column justify-content-between type-wrapper mx-auto">
                      <div className="memory-input">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            value="Photo"
                            name="flexRadio"
                            id="photoInput"
                            onChange={
                              handleChange
                            }
                            checked={
                              type ===
                              "Photo"
                            }
                          />
                          <label
                            className="form-check-label w-100 d-flex align-items-center"
                            htmlFor="photoInput"
                          >Photo</label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            value="Message"
                            name="flexRadio"
                            id="messageInput"
                            onChange={
                              handleChange
                            }
                            checked={
                              type ===
                              "Message"
                            }
                          />
                          <label
                            className="form-check-label w-100 d-flex align-items-center"
                            htmlFor="messageInput"
                          >Message</label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            value="Photo&message"
                            name="flexRadio"
                            id="messagePhotoInput"
                            onChange={
                              handleChange
                            }
                            checked={
                              type ===
                              "Photo&message"
                            }
                          />
                          <label
                            className="form-check-label w-100 d-flex align-items-center"
                            htmlFor="messagePhotoInput"
                          >Photo and message</label>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="col-12">
                    <SlideNextButton
                      ID="memoryType"
                      className="form-next d-flex align-items-center text-white bg-darkyellow justify-content-center mx-auto"
                      value="Next"
                    />
                  </div>
                </div>
              </SwiperSlide>
              {(type === "Message" || type === "Photo&message") &&
                <SwiperSlide className="swiper-slide mx-auto flex-column">
                  <div className="row w-100">
                    <div className="col-12">
                      <h3 className="swiper-slide__title text-deepblue text-center">Write a memory</h3>
                      <div className="memory-wrapper d-flex flex-column justify-content-between type-wrapper mx-auto">
                        <div className="form-group name-group w-100 mb-2">
                          <input
                            value={name}
                            onChange={(e) =>
                              nameHandler(e)
                            }
                            type="text"
                            className="form-control"
                            id="nameInput"
                            name="name"
                            minLength={2}
                            autoComplete="given-name"
                            placeholder="From whom? *"
                          />
                          {nameDirty &&
                            nameError && (
                              <label
                                className={
                                  "error"
                                }
                              >
                                {nameError}
                              </label>
                            )}
                        </div>
                        <div className="form-group name-group w-100 mb-2 mt-3">
                          <input
                            value={email}
                            onChange={(e) =>
                              emailHandler(e)
                            }
                            type="email"
                            className="form-control"
                            id="emailInput"
                            name="email"
                            maxLength={60}
                            minLength={4}
                            autoComplete="email"
                            placeholder="Your Email *"
                          />
                          {emailDirty && emailError && (
                            <label
                              className={
                                "error"
                              }
                            >
                              {emailError}
                            </label>
                          )}
                        </div>
                        <div className="form-group form-group--textarea mb-0 mt-3">
                          <textarea className="form-control form-control--textarea" id="messageInput" name="message"
                                    onChange={(e) => messageHandler(e)} rows={4}></textarea>
                          {messageDirty && messageError && (
                            <label className={"error"}>{messageError}</label>
                          )}
                        </div>
                        <div className="form-group form-group--notice text-left mb-0 mt-3">
                          <p className="mb-0">By submitting this post you are confirming that you have read our <Link
                            to="/pdf/Fenix_Privacy_Policy.pdf"><u>Privacy Policy</u></Link> and consent that we will
                            save relevant personal information.*</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      {type === "Message" ?
                        (nameDirty && nameError) || name === "" || (emailDirty && emailError) || email === "" || (messageDirty && messageError) || textMessage === "" ?
                          <SlideNextButton
                            ID="memoryText"
                            className="form-next d-flex align-items-center text-white bg-darkyellow justify-content-center mx-auto not-active"
                            value="Submit"
                            disabled={true}
                          /> :
                          loading ?
                            <SlideNextButton
                              ID="memoryText"
                              className="form-next d-flex align-items-center text-white bg-darkyellow justify-content-center mx-auto"
                              value="Submit"
                              disabled={true}
                              loading={true}
                            />
                            :
                            <SlideNextButton
                              ID="memoryText"
                              className="form-next d-flex align-items-center text-white bg-darkyellow justify-content-center mx-auto"
                              value="Submit" hasOnClick={true}
                              onClick={() => {
                                // console.log("Message Submit onClick", submitDone);
                                if (!submitDone) {
                                  handleSubmit();
                                } else {
                                }
                              }}
                            />
                        :
                        (nameDirty && nameError) || name === "" || (emailDirty && emailError) || email === "" || (messageDirty && messageError) || textMessage === "" ?
                          <SlideNextButton
                            ID="memoryType" disabled={true} value="Next"
                            className="form-next d-flex align-items-center text-white justify-content-center mx-auto not-active"/> :
                          <SlideNextButton
                            ID="memoryType" disabled={false} value="Next"
                            className="form-next d-flex align-items-center text-white bg-darkyellow justify-content-center mx-auto"/>}
                    </div>
                  </div>
                </SwiperSlide>}
              {(type === "Photo" || type === "Photo&message") &&
                <SwiperSlide className="swiper-slide mx-auto flex-column">
                  <div className="row w-100">
                    <div className="col-12">
                      <h3 className="swiper-slide__title text-deepblue text-center">Add a photo</h3>

                      <div className="memory-wrapper d-flex flex-column justify-content-between type-wrapper mx-auto">
                        <div className="form-group form-group--upload mb-0">
                          <label className="picture" htmlFor="pictureInput" tabIndex={0}>
                            <span className="picture__image">
                              {imageUrl && selectedImage && (
                                <img src={imageUrl} alt={selectedImage.name} height={225} width={400}/>
                              )}
                            </span>
                          </label>
                          <input accept="image/*" type="file" name="picture" id="pictureInput"
                                 onChange={(e) => setSelectedImage(e.target.files[0])}/>
                          {imageError && (
                            <label className={"error"}>{imageError}</label>
                          )}
                        </div>
                        {type === "Photo" && <div className="form-group name-group w-100 mb-2 mt-4">
                          <input
                            value={name}
                            onChange={(e) =>
                              nameHandler(e)
                            }
                            type="text"
                            className="form-control"
                            id="nameInputPhoto"
                            name="namePhoto"
                            minLength={2}
                            autoComplete="given-name"
                            placeholder="From whom? *"
                          />
                          {nameDirty &&
                            nameError && (
                              <label
                                className={
                                  "error"
                                }
                              >
                                {nameError}
                              </label>
                            )}
                        </div>}
                        {type === "Photo" && <div className="form-group name-group w-100 mb-2 mt-3">
                          <input
                            value={email}
                            onChange={(e) =>
                              emailHandler(e)
                            }
                            type="email"
                            className="form-control"
                            id="emailInputPhoto"
                            name="emailPhoto"
                            maxLength={60}
                            minLength={4}
                            autoComplete="email"
                            placeholder="Your Email *"
                          />
                          {emailDirty &&
                            emailError && (
                              <label
                                className={
                                  "error"
                                }
                              >
                                {emailError}
                              </label>
                            )}
                        </div>}
                        {type === "Photo" && <div className="form-group form-group--notice text-left mb-0 mt-3">
                          <p className="mb-0">By submitting this post you are confirming that you have read our <Link
                            to="/pdf/Fenix_Privacy_Policy.pdf"><u>Privacy Policy</u></Link> and consent that we will
                            save relevant personal information.*</p>
                        </div>}
                      </div>
                    </div>
                    <div className="col-12">
                      {type === "Photo" ? (nameDirty && nameError) && name === "" || (emailDirty && emailError) && email === "" || imageError ?
                          <SlideNextButton
                            ID="memoryImage" disabled={true} value="Submit"
                            className="form-next d-flex align-items-center text-white bg-darkyellow justify-content-center mx-auto not-active"
                          /> :
                          loading ?
                            <SlideNextButton
                              ID="memoryImage"
                              className="form-next d-flex align-items-center text-white bg-darkyellow justify-content-center mx-auto"
                              value="Submit"
                              disabled={true}
                              loading={true}
                            />
                            :
                            <SlideNextButton
                              ID="memoryImage"
                              className="form-next d-flex align-items-center text-white bg-darkyellow justify-content-center mx-auto"
                              value="Submit"
                              disabled={false}
                              hasOnClick={true}
                              onClick={() => {
                                // console.log("Photo Submit onClick", submitDone, state);
                                if (!submitDone) {
                                  handleSubmit();
                                } else {

                                }
                              }}
                            />
                        :
                        (nameDirty && nameError) || name === "" || (emailDirty && emailError) || email === "" || (messageDirty && messageError) || textMessage === "" || imageError ?
                          <SlideNextButton
                            ID="memoryImage" disabled={true} value="Submit"
                            className="form-next d-flex align-items-center text-white bg-darkyellow justify-content-center mx-auto not-active"
                          /> :
                          loading ?
                            <SlideNextButton
                              ID="memoryImage"
                              className="form-next d-flex align-items-center text-white bg-darkyellow justify-content-center mx-auto"
                              value="Submit"
                              disabled={true}
                              loading={true}
                            />
                            :
                            <SlideNextButton
                              ID="memoryImage"
                              className="form-next d-flex align-items-center text-white bg-darkyellow justify-content-center mx-auto"
                              value="Submit"
                              disabled={false}
                              hasOnClick={true}
                              onClick={() => {
                                // console.log("Image - Submit onClick", submitDone);
                                if (!submitDone) {
                                  handleSubmit();
                                } else {

                                }
                              }}
                            />
                      }
                    </div>
                  </div>
                </SwiperSlide>}
              <SwiperSlide
                className="swiper-slide swiper-slide--last"
              >
                <div className="row w-100 no-gutters">
                  <div className="col-12">
                    <div className="result-wrapper d-flex flex-column justify-content-between align-items-center">
                      <img
                        src="/images/icons/icon_dove-deepblue.svg"
                        width="115"
                        height="130"
                        alt="Fenix icon_dove"
                      />
                      <h4 className="result-title text-center">
                        Thank you {" "}
                        {name} for your memory
                      </h4>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </form>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
}