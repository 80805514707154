import * as React from "react";
import {useState} from "react";
import {useFenixContext} from "../../helpers/context";

export default function DonateFormModal() {
  const {state, dispatch} = useFenixContext()
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [textarea, setTextarea] = useState('');
  const [emailDirty, setEmailDirty] = useState(false);
  const [nameDirty, setNameDirty] = useState(false);
  const [surnameDirty, setSurnameDirty] = useState(false);
  const [emailError, setEmailError] = useState('Please enter a correct email address');
  const [nameError, setNameError] = useState('Letters only, please.');
  const [surnameError, setSurnameError] = useState('Letters only, please.');
  const [focusedName, setFocusedName] = useState(false)
  const [focusedSurname, setFocusedSurname] = useState(false)
  const [focusedText, setFocusedText] = useState(false)
  const [focusedEmail, setFocusedEmail] = useState(false)

  const onFocusName = () => setFocusedName(true)
  const onBlurName = () => setFocusedName(false)
  const onFocusSurname = () => setFocusedSurname(true)
  const onBlurSurname = () => setFocusedSurname(false)
  const onFocusEmail = () => setFocusedEmail(true)
  const onBlurEmail = () => setFocusedEmail(false)
  const onFocusTextarea = () => setFocusedText(true)
  const onBlurTextarea = () => setFocusedText(false)

  const nameHandler = (e: any) => {
    setName(e.target.value)
    const letters = /^[A-Za-z]+$/;
    if (e.target.value.length < 2 || !letters.test(String(e.target.value))) {
      setNameError('Letters only, please.')
      setNameDirty(true)
    } else {
      setNameDirty(false)
      setNameError('')
    }
  }

  const surnameHandler = (e: any) => {
    setSurname(e.target.value)
    const letters = /^[A-Za-z]+$/;
    if (e.target.value.length < 2 || !letters.test(String(e.target.value))) {
      setSurnameError('Letters only, please.')
      setSurnameDirty(true)
    } else {
      setSurnameDirty(false)
      setEmailError('')
    }
  }

  const emailHandler = (e: any) => {
    setEmail(e.target.value)
    const email = /\S+@\S+\.\S+/;
    if (!email.test(String(e.target.value))) {
      setEmailError('Please enter a correct email address.')
      setEmailDirty(true)
    } else {
      setEmailError('')
      setEmailDirty(false)
    }
  }

  const textareaHandler = (e: any) => {
    setTextarea(e.target.value)
  }

  return (
    <div className="modal fade show">
      <div className="modal-dialog modal-dialog-centered flowers-popup">
        <div className="modal-content" style={stylesInline.bgColor}>
          <div className="modal-header d-flex flex-column align-items-center pt-4">
            <p className="mb-0">Donate to </p>
            <h3 className="modal-title" id="modalCenterTitle">Canser Fond</h3>
            <button type="button"
                    className="close"
                    onClick={() => dispatch({type: "hideModal"})}
            >
              <img src="/images/icons/icon_close-darkblue.svg" alt="close button"/>
            </button>
          </div>
          <div className="modal-body">
            <form id="donateForm" className="form-style book-modal">
              <div className="row book-modal__fields no-gutters">
                <div className="col-6 d-none d-lg-block">
                  <div className="modal-card bg-white w-100 text-center">
                    <img src="/images/hxuuc34l8xv2kldsn9js 1.png" width={260} height={150} alt="fund logo"
                         className="mb-4"/>
                    <h4 className="mb-4 text-deepblue">Give a gift</h4>
                    <p className="mb-4"><b>Cancer Fond</b></p>
                    <p className="mb-0">Lorem ipsum dolor sit aasdmet, consectetur adipiscing elit, sed do eiusmod
                      tempor incididunt qwe labore </p>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="row submit-item-hide">
                    <div className="col-12">
                      <div className="form-group name-group w-100 mb-2">
                        <label htmlFor="nameInput">
                          Your first name{" "}
                          <span className="obligatory">
                                  *
                                </span>
                        </label>
                        <input
                          onChange={(e) =>
                            nameHandler(e)
                          }
                          value={name}
                          type="text"
                          className="form-control"
                          id="nameInput"
                          name="name"
                          minLength={2}
                          autoComplete="given-name"
                          placeholder="John"
                        />
                        {nameDirty &&
                          nameError && (
                            <label
                              className={
                                "error"
                              }
                            >
                              {nameError}
                            </label>
                          )}
                      </div>
                      <div className="form-group surname-group w-100 mb-2">
                        <label htmlFor="surnameInput">
                          Your surname{" "}
                          <span className="obligatory">
                                  *
                                </span>
                        </label>
                        <input
                          onChange={(e) =>
                            surnameHandler(
                              e
                            )
                          }
                          value={surname}
                          type="text"
                          className="form-control"
                          id="surnameInput"
                          name="surname"
                          minLength={2}
                          autoComplete="family-name"
                          placeholder="Doe"
                        />
                        {surnameDirty &&
                          surnameError && (
                            <label
                              className={
                                "error"
                              }
                            >
                              {
                                surnameError
                              }
                            </label>
                          )}
                      </div>
                      <div className="form-group mb-2">
                        <label htmlFor="emailInput">
                          Email{" "}
                          <span className="obligatory">
                                  *
                                </span>
                        </label>
                        <input onChange={e => emailHandler(e)}
                               value={email} type="email" onFocus={onFocusEmail} onBlur={onBlurEmail}
                               className="form-control form-control__input" id="emailInput"
                               name="email" autoComplete="email"/>
                        {(emailDirty && emailError) && <label className={'error'}>{emailError}</label>}
                      </div>
                      <div className="form-group form-group--textarea mb-0">
                        <label htmlFor="messageInput">Your message</label>
                        <textarea className="form-control form-control__textarea" id="messageInput"
                                  name="message" rows={6} onChange={e => textareaHandler(e)}
                                  onFocus={onFocusTextarea} onBlur={onBlurTextarea}></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row no-gutters book-modal__submit">
                <div className="col-12 d-flex  justify-content-center">
                  <button className="text-deepblue bg-white">Register</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
}

const stylesInline: { [key: string]: React.CSSProperties } = {
  bgColor: {
    backgroundColor: '#FFBBF4'
  },
};