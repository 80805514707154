import * as React from "react";
import {Link} from "gatsby"

import TemplateHead from "./template-head";
import Hero from './hero';
import Layout from "./layout";
import TrustpilotCarousel from "./trustpilot/trustpilot-carousel";
import BlockMap from "./blocks/block-map";
import Section from "./section";
import FlowersSection from "./flowers-section";
import RegisterCard from "./register-card";
import MapSingleLocation from "./map/single-location";
import ArticlesGridSelected from "./articles-selected";
import {useEffect, useState} from "react";
import MemorialGrid from "./memorial-grid";

const Registration = (props: any) => {

  const [dataObituary, setDataObituary] = useState(props?.location?.state?.data);
  useEffect(() => {
    if (dataObituary == null) {
      fetch(process.env.FENIX_LOCATION_AJAX_URL + "_seo-scripts/memorial/memorials.json")
        .then((response) => {
          if (response) {
            console.log("response", response);
            return response.json();
          }
          throw new Error('Fetch error');
        })
        .then((memorialsDataIda: any) => {
          console.log(memorialsDataIda)
          console.log(props.memoryId)
          const dataObituaryData = memorialsDataIda?.list.filter((oneItem) => oneItem.url == '/memorial/' + props.memoryId + '/')[0];
          console.log(dataObituaryData)
          props.setmeta(dataObituaryData);
          setDataObituary(dataObituaryData);
          //update memorial data from IDA to remote file
          fetch(process.env.FENIX_LOCATION_AJAX_URL + "_seo-scripts/memorial/");
        })
        .catch((error) => {
          console.log("response error", error);
        });
    } else {
      props.setmeta(dataObituary);
    }
  }, []);
  document.title = "In memory of " + dataObituary?.name + ' - Fenix Funeral Directors';

  return (
    <Layout>
      <Hero
        sectionClassName="hero-section hero-section--registration pl-4 pr-4"
        contentClassName="hero-content hero-content--registration pl-xl-5 pr-xl-5"
        imageMemory={dataObituary?.photo ? dataObituary?.photo : ""}
        altImages="Fenix Funeral Directors"
      >
        <img src="/images/icons/icon_flower-white.svg" alt="memorial icon"
             className="registration-icon" width={115} height={115}/>
        {dataObituary?.name && <h1 className="text-center text-white">{dataObituary?.name}</h1>}
        {dataObituary?.date && <p className="text-center text-white hero-content__dates">{dataObituary?.date}</p>}
        {dataObituary?.url && <Link to={dataObituary?.url}
                                    className="registration-memorial text-white text-center d-flex justify-content-center">Memorial
          page</Link>}
        <div className="block-wrapper pt-5 mt-3">
          <div className="row no-gutters book-registration d-flex flex-column flex-xl-row">
            <div className="col-12 d-flex flex-column justify-content-center">
              <h3 className="text-white mb-3">Welcome to the funeral service</h3>
              {dataObituary?.time &&
                <p className="book-registration__date text-white mb-0">Date of
                  funeral: <span>{dataObituary?.time}</span></p>}
              {dataObituary?.location && <p className="book-registration__location text-white">Location of
                funeral: <span>{dataObituary?.location}</span></p>}
            </div>
            {/*<div className="col-12 col-xl-5 d-flex align-items-center justify-content-center">*/}
            {/*  <RegisterCard/>*/}
            {/*</div>*/}
          </div>
        </div>
      </Hero>
      {/*<FlowersSection/>*/}
      <Section
        sectionClass={"pl-4 pr-4 section-container section-container--registration"}
      >
        <div className="block-wrapper">
          <div className="row no-gutters d-flex flex-column flex-xl-row pl-xl-5 pr-xl-5">
            <div className="col-12 col-xl-7 d-flex align-items-end pr-xl-5 mb-4 mb-xl-0">
              <div className="registration-flowers">
                <h2 className="text-white">Flower Tributes</h2>
                <p className="text-white">When you have suffered a loss, it can be hard to know where to start. A
                  flower tribute is a beautiful and heartfelt way to say farewell and let your loved ones know you're
                  thinking of them.</p>
                <Link
                  className="book-flower__button align-self-start text-deepblue bg-white d-flex justify-content-center align-items-center"
                  to="/products/flowers/">View Our Selection</Link>
              </div>
            </div>
            <div className="col-12 col-xl-5">
              <div className="row no-gutters pt-4">
                <div className={"col-12 registration-obituary text-white text-center"}>
                  <span>{dataObituary?.obituary ? dataObituary?.obituary : "In loving memory of " + dataObituary?.name.split(" ")[0] + ". Rest in Peace."}</span>
                </div>
                {dataObituary?.url && <div className="col-12 text-right mt-3 pt-2 pt-xl-5">
                  <Link to={dataObituary?.url} className="text-darkyellow registration-obituary__link"><u>Memorial
                    page</u></Link>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </Section>
      <ArticlesGridSelected/>
      {/*<Section*/}
      {/*  sectionClass={"pl-4 pr-4 section-container section-container--map"}*/}
      {/*>*/}
      {/*  <BlockMap*/}
      {/*    rowClass="row no-gutters flex-column-reverse flex-lg-row-reverse"*/}
      {/*    col1Class="col-12 col-lg-4 col-bottom-right bg-white pl-3 pl-md-5 pr-3 pr-md-5 pt-4 pt-md-5 pb-4 pb-md-5"*/}
      {/*    col2Class="col-12 col-lg-8 col-top-left"*/}
      {/*    h2={"Kyrkan"}*/}
      {/*    text={*/}
      {/*      "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>"*/}
      {/*    }*/}
      {/*    map={*/}
      {/*      <MapSingleLocation*/}
      {/*        key={`fenix-map-single`}*/}
      {/*        lat={51.509865}*/}
      {/*        lng={-0.118092}*/}
      {/*        hidePin={true}*/}
      {/*      />*/}
      {/*    }*/}
      {/*  />*/}
      {/*</Section>*/}
      <TrustpilotCarousel/>
    </Layout>
  )
}

export default Registration