import * as React from "react"

import {useFenixContext} from '../helpers/context';

export default function ButtonModal(props: any) {
  const {state, dispatch} = useFenixContext()
  return (
    <button className={props.buttonClassname}
            onClick={() => dispatch({type: props.buttonType, payload: props.data})}>{props.buttonTitle}</button>
  );
}