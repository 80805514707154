import * as React from "react";
import {Script} from "gatsby";
import loadable from "@loadable/component";

import {FenixProvider, useFenixContext} from "../helpers/context";
import BookFormModal from "./modal/booking";
import DonateFormModal from "./modal/donate";
import CandleFormModal from "./modal/candle";
import MemoryFormModal from "./modal/memory";
import useEventListener from "@use-it/event-listener";
import "swiper/scss";
import "swiper/scss/pagination";
import "../styles/global.scss";
import "../styles/header.scss";
import "../styles/footer.scss";
import "../styles/global-components.scss";

const PrimaryHorizontal = loadable(() => import('./primary-horizontal'))

const ESCAPE_KEYS = ["27", "Escape"];

function ModalDisplay(props) {
  const {state, dispatch} = useFenixContext();

  function handler({key}) {
    if (ESCAPE_KEYS.includes(String(key))) {
      dispatch({type: "hideModal"});
    }
  }

  props.parentCallback(state.showModalBooking);

  const useKeyDown = () => useEventListener("keydown", handler);
  useKeyDown();

  if (state.showModalBooking === false) return null;
  if (state.showModalBooking === true) return <BookFormModal/>;
  if (state.showModalDonate === false) return null;
  if (state.showModalDonate === true) return <DonateFormModal/>;
  if (state.showModalCandle === false) return null;
  if (state.showModalCandle === true) return <CandleFormModal/>;
  if (state.showModalMemory === false) return null;
  if (state.showModalMemory === true) return <MemoryFormModal/>;
  return null;
}

export default function LayoutHorizontal(props) {
  const handleCallback = (childData) => {
    if (typeof document !== "undefined") {
      childData === true ? document.body.classList.add('modal-open') : document.body.classList.remove('modal-open');
    }
  }

  return (
    <FenixProvider>
      <Script key={`fenixfamily-gatsbyjs-call-metrics-script`} async src="https://223986.tctm.co/t.js"/>
      <Script key={`fenixfamily-gatsbyjs-trustpilot-script`} async
              src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"/>
      <ModalDisplay parentCallback={handleCallback}/>
      <PrimaryHorizontal>{props.children}</PrimaryHorizontal>
    </FenixProvider>
  );
}